import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, Input, InputLabel, Link, MenuItem, Modal, Paper, Select, TableFooter, TextField, Typography } from "@mui/material";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APIManager from '../../lib/apiManager';
import EditorTest from "../EditorTest";
import WYSIWYGEditor from "../WYSIWYGEditor";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Stack } from '@mui/material';
import { Check as CheckIcon, Delete as DeleteIcon, Edit as EditIcon, InsertDriveFile, Visibility } from "@mui/icons-material";
import NuevoContratoModal from "./components/NuevoContratoModal";
import { sistema } from '../../model/Sistema';
import jsPDF from "jspdf";
import { getVariables, variablesAplicadas } from "../../lib/templateFunctions";
import { render } from "react-dom";

const Contrato = ({setBotonera, espaciosAdministrables}) => {

  const [templates, setTemplates] = useState([]);
  const [contrato, setContrato] = useState(null);
  const [editando, setEditando] = useState(false);
  const [contratoParaDocumento, setContratoParaDocumento] = useState(null);

  const [cliente, setCliente] = useState(null);
  const [nombre, setNombre] = useState(null);
  const [descripcion, setDescripcion] = useState(null);
  const [numero, setNumero] = useState(null);
  const [contenido, setContenido] = useState(null);
  const [espacios, setEspacios] = useState([]);

  let { contratoId } = useParams();

  const [espacio, setEspacio] = useState(null);
  const [vigenciaDesde, setVigenciaDesde] = useState(null);
  const [vigenciaHasta, setVigenciaHasta] = useState(null);
  const [order, setOrder] = useState('asc');
  const [puestosOrderBy, setPuestosOrderBy] = useState('nombre');
  const [ubicacionesOrderBy, setUbicacionesOrderBy] = useState('nombre');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [puestosPerPage, setPuestosPerPage] = useState(5);
  const [creditos, setCreditos] = useState(0);
  const [valor, setValor] = useState(0);
  const [moneda, setMoneda] = useState('');
  const [aprobador, setAprobador] = useState(null);
  const [aprobadoPor, setAprobadoPor] = useState('');
  const [fechaDeAprobacion, setFechaDeAprobacion] = useState(null);

  const [garantia, setGarantia] = useState(0);
  const [visitas, setVisitas] = useState(0);
  const [impresiones, setImpresiones] = useState(0);

  const [puestos, setPuestos] = useState([]);
  const [ubicaciones, setUbicaciones] = useState([]);

  const [agregandoUbicacion, setAgregandoUbicacion] = useState(false);
  const [espacioParaUbicacion, setEspacioParaUbicacion] = useState(null);
  const [ubicacionesDelEspacio, setUbicacionesDelEspacio] = useState([]);
  const [ubicacion, setUbicacion] = useState(null);

  const [creditosSugeridos, setCreditosSugeridos] = useState(0);
  const [firmante, setFirmante] = useState(null);

  const [viendoTemplate, setViendoTemplate] = useState(false);
  const [template, setTemplate] = useState(null);
  const [variablesConInput, setVariablesConInput] = useState([]);

  const [nuevoModalOpen, setNuevoModalOpen] = useState(false);

  const [procesando, setProcesando] = useState(null);
  const [documentoURL, setDocumentoURL] = useState(null);
  const [documentoContent, setDocumentoContent] = useState('');

  const [clienteIncompleto, setClienteIncompleto] = useState(false);
  const [preEditarCliente, setPreEditarCliente] = useState(false);

  const [hayCambios, setHayCambios] = useState(false);

  const navigate = useNavigate();

  const columns_membresias = [
    {
      id: 'nombre',
      numeric: false,
      disablePadding: true,
      label: 'Nombre',
    },
    {
      id: 'puestos',
      numeric: false,
      disablePadding: true,
      label: 'Puestos',
    },
    {
      id: 'creditos',
      numeric: false,
      disablePadding: true,
      label: 'Créditos',
    },
  ];

  const columns_ubicaciones = [
    {
      id: 'espacio',
      numeric: false,
      disablePadding: true,
      label: 'Espacio',
    },
    {
      id: 'sector',
      numeric: false,
      disablePadding: true,
      label: 'Sector',
    },
    {
      id: 'tipo',
      numeric: false,
      disablePadding: true,
      label: 'Tipo',
    },
    {
      id: 'nombre',
      numeric: false,
      disablePadding: true,
      label: 'Nombre',
    },
    {
      id: 'puestos',
      numeric: false,
      disablePadding: true,
      label: 'Puestos',
    },
  ];

  const dateFrom = (dateSource) => {
    if (!!dateSource && !!dateSource.date) {
      return new Date(dateSource.date);
    } else if (dateSource instanceof Date || !dateSource) {
      return dateSource;
    } else {
      return new Date(dateSource);
    }
  }

  const handleChangeVigenciaHasta = (newDate) => {
    setVigenciaHasta(new Date(newDate));
  }

  const handleChangeVigenciaDesde = (newDate) => {
    setVigenciaDesde(new Date(newDate));
  }

  const handleAprobarContrato = () => {
    setAprobador(sistema.usuario);
    setFechaDeAprobacion(new Date());
  }

  const handleVerPreview = () => {
    if (!viendoTemplate) {
      // Actualizamos contrato con los inputs
      setContratoParaDocumento(contratoActualizado());
    }
    setViendoTemplate(!viendoTemplate);
  }

  const handleGenerarPDF = () => {
    APIManager.sendRequest('post', 'contratos', 'generarPDF', { documento: documentoContent, contrato: contratoParaDocumento }, response => {
      if (response.success) {
        // Se grabó el PDF y tenemos el filename para poner en el contrato
        setDocumentoURL(response.data.documentoURL);
        window.open(response.data.documentoURL);
      }
      setProcesando(false);
    });
  }

  const handleGenerarPDF_bak = () => {
    const contratoParaPDF = contratoActualizado();
    setContratoParaDocumento(contratoParaPDF);
    if (!!contratoParaPDF && !!contratoParaPDF.id && !!template
      // && !documentoURL
    ) {
      setProcesando({title: 'Generando PDF...'});
      // const doc = new jsPDF({ unit: 'px', format: 'legal' });
      // Buscamos las variables para aplicar
      variablesAplicadas(template, variablesConInput, contratoParaPDF, htmlBody => {
        // Con las variables aplicadas generamos el PDF
        APIManager.sendRequest('post', 'contratos', 'generarPDF', { documento: htmlBody, contrato: contratoParaPDF }, response => {
          if (response.success) {
            // Se grabó el PDF y tenemos el filename para poner en el contrato
            setDocumentoURL(response.data.documentoURL);
            window.open(response.data.documentoURL);
          }
          setProcesando(false);
        });
/*
        doc.html(''
          // '<html><head>'
          // + '<script src = "https://html2canvas.hertzen.com/dist/html2canvas.js" ></script>'
          // + '<style>@CHARSET "UTF-8";</style>'
          // + '</head><body>'
          // + '<div style = "font-size: 14px; padding: 20px; width: 1000px" > '
          + htmlBody
          // + '</div> '
          // + "<script src='../../dist/jspdf.debug.js'></script>"
          // + '</body></html>'
          , {
          filename: contratoParaDocumento.descripcion,
          // width: 1800,
          // windowWidth: 1800,
          autoPaging: 'text',
          x: 0,
          y: 0,
          html2canvas: {scale: 0.45},
          callback: doc => {
            const pdf = doc.output();
            APIManager.sendRequest('post', 'contratos', 'grabarDocumento', { documento: pdf, contrato: contratoParaDocumento }, (response) => {
              if (response.success) {
                // Se grabó el PDF y tenemos el filename para poner en el contrato
                setDocumentoURL(response.data.documentoURL);
                window.open(response.data.documentoURL);
              }
              setProcesando(false);
            });
            // doc.save('contrato.pdf',{returnPromise: true}).then(response => setProcesando(false));
          }
        });
 */
      });
    }
  }

  const handleVerPDF = () => {
    window.open(contrato.documentoURLFull);
  }

  const editarContrato = () => {
    setEditando(!editando);
  }

  const contratoActualizado = (contratoBody) => {
    const contratoActualizado = { ...contrato };
    contratoActualizado.body = contratoBody;
    contratoActualizado.nombre = nombre;
    contratoActualizado.descripcion = descripcion;
    contratoActualizado.numero = numero;
    contratoActualizado.valor = valor;
    contratoActualizado.moneda = moneda;
    contratoActualizado.creditos = creditos;
    contratoActualizado.garantia = garantia;
    contratoActualizado.visitas = visitas;
    contratoActualizado.impresiones = impresiones;
    contratoActualizado.vigenciaDesde = new Date(vigenciaDesde?.toDateString());
    contratoActualizado.vigenciaHasta = new Date(vigenciaHasta?.toDateString()+' 23:59:59');
    contratoActualizado.aprobador = aprobador;
    contratoActualizado.fechaDeAprobacion = fechaDeAprobacion;
    contratoActualizado.aprobado = !!fechaDeAprobacion;
    contratoActualizado.espacio = espacio;
    contratoActualizado.template = template;
    contratoActualizado.template_id = !!template ? template.id : null;
    contratoActualizado.ubicaciones = ubicaciones;
    contratoActualizado.puestos = puestos;

    variablesConInput.forEach(variable => {
      contratoActualizado[variable.nombre] = variable.value;
    });

    return contratoActualizado;

  }

  const erroresDeInputs = () => {
    const errores = [];
    Object.values(variablesConInput).forEach(variable => {
      if (!variable.value) {
        errores.push(variable.descripcion+' requerido!');
      }
    });
    Object.values(window.document.getElementsByTagName('input')).forEach(element => {
      if (element.required && !element.value) {
        errores.push(element.label + ' requerido!');
      }
    });
    return errores;
  }

  const guardarContrato =  () => {
    const contratoAEnviar = contratoActualizado();
    console.log('guardarContrato', contrato, variablesConInput, contratoAEnviar);
    // Check de inputs (no deberia hacer falta para guardar, SI para aprobar)
    const errores = erroresDeInputs();
    if (!!contratoAEnviar.aprobador && (errores.length > 0)) {
      alert(errores.toString().replaceAll('!,','!\n'));
    } else {
      // A guardar se ha dicho!
      APIManager.sendRequest('post', 'contratos', 'modificarContrato', {contrato: contratoAEnviar}, (response) => {
        setEditando(false); // Se guardó y ya no estamos editando
        setContrato(contratoAEnviar);
      });
    }
  }

  const cancelarEdicion = () => {
    setEditando(false);
    setNombre(contrato.nombre);
    setDescripcion(contrato.descripcion)
  }

  const cargarTemplate = (templateID, callback) => {
    APIManager.sendRequest('post', 'contratos', 'getTemplate', { id: templateID }, (templateResponse) => {
      if (templateResponse.success) {
        if (!!callback) {
          callback(templateResponse);
        }
      }
    });
  }

  const nuevoContratoPara = (clienteElegido, templateElegido, vigenciaDesde, contratoExistente) => {
    // Creamos un contrato para el clienteElegido basado en el templateElegido
    if (!!contratoExistente) {
      // Es una renovacion
      const idRenovado = contratoExistente.id;
      const nuevoContrato = { ...contratoExistente };
      nuevoContrato.id = null;
      nuevoContrato.idRenovado = idRenovado;
      nuevoContrato.aprobador = null;
      nuevoContrato.aprobado = false;
      nuevoContrato.fechaDeAprobacion = null;
      nuevoContrato.vigenciaDesde = vigenciaDesde;
      nuevoContrato.vigenciaHasta = null;
      nuevoContrato.fechaDeAlta = new Date();
      nuevoContrato.firmante = null;
      nuevoContrato.documentoURL = null;
      const numeroAnterior = contratoExistente.numero.split('-');
      nuevoContrato.numero = numeroAnterior[0] + '-' + numeroAnterior[1] + '-' + (parseInt(numeroAnterior[2]) + 1);
      if (!nuevoContrato.espacio.activo) {
        nuevoContrato.espacio = sistema.espacio;
      }

      if (!!templateElegido) {
        cargarTemplate(templateElegido.id,(templateResponse) => {
          nuevoContrato.template = templateResponse.data.template;
          setTemplate(nuevoContrato.template);
          setContrato(nuevoContrato);
          setEditando(true);
        });
/*
        APIManager.sendRequest('post', 'contratos', 'getTemplate', { id: templateElegido.id }, (templateResponse) => {
          if (templateResponse.success) {
            nuevoContrato.template = templateResponse.data.template;
            setTemplate(nuevoContrato.template);
            setContrato(nuevoContrato);
            setEditando(true);
          }
        });
 */
      } else {
        nuevoContrato.template = null;
        setTemplate(null);
        setContrato(nuevoContrato);
        setEditando(true);
      }
    } else {
      // Es un nuevo contrato de cero
      APIManager.sendRequest('post', 'contratos', 'getNuevoContrato', { cliente: clienteElegido, espacio, vigenciaDesde }, (response) => {
        if (response.success) {
          const nuevoContrato = response.data.contrato;
          cargarTemplate(templateElegido.id, (templateResponse) => {
            if (templateResponse.success) {
              nuevoContrato.template = templateResponse.data.template;
              setTemplate(nuevoContrato.template);
              setContrato(nuevoContrato);
              setEditando(true);
            }
          });
/*
          APIManager.sendRequest('post', 'contratos', 'getTemplate', { id: templateElegido.id }, (templateResponse) => {
            if (templateResponse.success) {
              nuevoContrato.template = templateResponse.data.template;
              setTemplate(nuevoContrato.template);
              setContrato(nuevoContrato);
              setEditando(true);
            }
          });
 */
        }
      });
    }
  }

  const handlePuestosChange = (puesto, value) => {
    const nuevoPuesto = puesto;
    nuevoPuesto.puestos = value;
    setPuestos(puestos.map(item => (item.modo_de_contratacion_id === nuevoPuesto.modo_de_contratacion_id) ? nuevoPuesto : item));
  }

  const handleVariable = (variable, value) => {
    variable.value = value;
    setVariablesConInput([...variablesConInput]);
  }

  const handleGuardarContrato = (event) => {
    if (!template) {
      alert('Debe seleccionar un template de contrato');
    } else {
      guardarContrato();
    }
  }

  const handleRenovarContrato = (event) => {
    console.log('renovarContrato', contrato, variablesConInput, template);
    // const nuevoContrato = contratoActualizado();
    // nuevoContrato.template_id = template.id;
    if (!!contrato) {
      nuevoContratoPara(contrato.cliente, template, new Date(), contrato);
    }
  }

  const handleAgregarUbicacion = () => {
    setUbicaciones([...ubicaciones, ubicacion]);
    handleCancelarUbicacion();
  }

  const handleCancelarUbicacion = () => {
    setAgregandoUbicacion(false);
    setEspacioParaUbicacion(null);
    setUbicacionesDelEspacio([]);
    setUbicacion(null);
  }

  const handleQuitarUbicacion = (ubicacionAQuitar) => {
    setUbicaciones(ubicaciones.filter(item => item.id !== ubicacionAQuitar.id));
  }

  const handleEditarCliente = () => {
    navigate('/clientes/' + (cliente.id ?? cliente.cliente_id));
  }

  const handleCancelarEditarCliente = () => {
    setPreEditarCliente(false);
  }

  const puedeEditar = () => {
    // Devuelve si el usuario puede editar o no el contrato
    if (!contrato || !espacio || !espaciosAdministrables || (espaciosAdministrables.length === 0)) {
      return false;
    }
    const puedeEditarEnElEspacio = espaciosAdministrables.find(item => item.id === espacio.id);
    return !!contrato && !!puedeEditarEnElEspacio && !!contrato.aprobador;
  }

  useEffect(() => {
    if (!!espacioParaUbicacion) {
      setUbicacion(null);
      setUbicacionesDelEspacio([]);
      APIManager.getList('oficinas', { activos: true, espacioID: espacioParaUbicacion.id }, response => {
        if (response.success) {
          setUbicacionesDelEspacio(response.data.listado);
        }
      })
    }
  }, [espacioParaUbicacion]);

  useEffect(() => {
    if (!!contratoId && isNaN(contratoId) && (contratoId === "nuevo")) {
      // Es un contrato nuevo
      setEspacio(sistema.espacio);
    } else {
      // Es un contrato existente
      APIManager.sendRequest('post', 'contratos', 'getContrato', {id: contratoId}, (response) => {
        if (response.success) {
          setContrato(response.data.contrato);
          if (!!response.data.contrato.template_id) {
            cargarTemplate(response.data.contrato.template_id,(response) => {
              if (response.success) {
                setTemplate(response.data.template);
              }
            });
/*
            APIManager.sendRequest('post', 'contratos', 'getTemplate', {id: response.data.contrato.template_id}, (response) => {
              if (response.success) {
                setTemplate(response.data.template);
              }
            })
 */
          }
        }
      })
    }


  }, [contratoId]);

  useEffect(() => {
    setCreditosSugeridos(puestos.reduce((anterior, actual) => anterior + (actual.creditos * actual.puestos), 0));
  }, [puestos]);

  useEffect(() => {
    if (!!contrato) {
      sistema.actual.contrato = contrato;
      setContratoParaDocumento(contrato);
      setCliente(contrato.cliente);
      // setEspacio(espacios.find(item => item.id === contrato.espacio.id));
      setNombre(contrato.nombre);
      setDescripcion(contrato.descripcion);
      setNumero(contrato.numero);
      setVigenciaDesde(dateFrom(contrato.vigenciaDesde));
      setVigenciaHasta(dateFrom(contrato.vigenciaHasta));
      setPuestos(Object.values(contrato.puestos));
      setUbicaciones(Object.values(contrato.ubicaciones));
      setCreditos(contrato.creditos ?? '');
      setValor(contrato.valor ?? '');
      setMoneda(contrato.moneda ?? '');
      setAprobador(contrato.aprobador);
      setFechaDeAprobacion(contrato.fechaDeAprobacion);
      setDocumentoURL(contrato.documentoURL);
      setFirmante(contrato.firmante);
      setGarantia(contrato.garantia);
      setVisitas(contrato.visitas ?? '');
      setImpresiones(contrato.impresiones);
      if (!!variablesConInput && (variablesConInput.length > 0)) {
        setVariablesConInput(variablesConInput.map(variable => {
          if (!!contrato[variable.nombre]) {
            variable.value = contrato[variable.nombre];
          }
          return variable;
        }));
      }
    } else {
      setNuevoModalOpen(true);
    }
  }, [contrato]);

  useEffect(() => {
    if (!!template) {
      // El template fue cargado, buscamos las variables con inputs para agregar al formulario
      sistema.actual.template = template;
      const variablesAAplicar = [];
      getVariables(response => {
        const variables = response.data.listado;
        variables.forEach(variable => {
          if (template.body.indexOf('%' + variable.variable + '%') !== -1) {
            // El template tiene la variable
            if (!!variable.tipo) {
              if (!!contrato && !!contrato[variable.nombre]) {
                variable.value = contrato[variable.nombre];
              }
              variablesAAplicar.push(variable);
            }
          }
        });
        setVariablesConInput(variablesAAplicar);
      });
    }
  }, [template]);

  useEffect(() => {
    setHayCambios(true);
    if (!!contratoParaDocumento) {
      setContratoParaDocumento(contratoActualizado());
    }
  }, [nombre, descripcion, numero, valor, moneda, creditos, garantia, visitas, impresiones, variablesConInput, fechaDeAprobacion, vigenciaDesde, vigenciaHasta, aprobador, puestos, ubicaciones]);

  useEffect(() => {
    if (!!aprobador) {
      setAprobadoPor(aprobador.descripcion);
    } else {
      setAprobadoPor('');
    }
  },[aprobador]);

  useEffect(() => {
    sistema.actual.contratoParaDocumento = contratoParaDocumento;
    if (hayCambios) {
      setHayCambios(false);
    }
  }, [contratoParaDocumento]);

  useEffect(() => {
    if (!!cliente) {
      setClienteIncompleto(!cliente.active || !cliente.cuit || !cliente.email || !cliente.direccion || !cliente.razonSocial);
    }
  }, [cliente]);

  useEffect(() => {
    setBotonera([
      { title: 'Volver', label: 'Volver', variant: 'outlined', onClick: () => { navigate(-1); } },
      { title: 'Editar', label: 'Editar', variant: 'contained', visible: !editando, disabled: puedeEditar(), onClick: () => { setEditando(true) } },
      { title: 'Guardar', label: 'Guardar', variant: 'contained', disabled: !contrato || !template, visible: !!editando, onClick: handleGuardarContrato },
      { title: 'Renovar', label: 'Renovar', variant: 'contained', visible: !!contrato && !!contrato.id, onClick: handleRenovarContrato },
    ]);

  }, [editando, contrato, template, variablesConInput, hayCambios]);

  useEffect(() => {
    if (!!template && !!contratoParaDocumento && !!variablesConInput && !hayCambios) {
      variablesAplicadas(template, variablesConInput, contratoParaDocumento, htmlBody => {
        setDocumentoContent(htmlBody);
        // sistema.actual.documentoContent = htmlBody;
      });
    }
  }, [template, contratoParaDocumento, variablesConInput, hayCambios]);

  useEffect(() => {
    // Cargamos los espacios
    APIManager.getList('espacios', { orderBy: 'nombre', active: true }, (response) => {
      if (response.success) {
        setEspacios(response.data.listado);
      }
    });
    // Cargamos los templates
    APIManager.getList('templatesDeContrato', { orderBy: 'nombre', active: true }, (response) => {
      if (response.success) {
        setTemplates(response.data.listado);
      }
    });

  }, []);

  useEffect(() => {
    if (!!espacios && !!contrato && (espacios.length > 0)) {
      setEspacio(espacios.find(item => item.id === contrato.espacio.id));
    }
  }, [espacios, contrato]);

  return (
    <Paper sx={{ padding: '14px' }}>
      {!!procesando && <Dialog open={true}><DialogTitle>{procesando?.title ?? ''}</DialogTitle><DialogContent><CircularProgress /></DialogContent></Dialog>}
      {!!preEditarCliente && <Dialog
        open={true}
        onClose={handleCancelarEditarCliente}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Editar el Cliente?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'Si desea editar el cliente '+cliente.razonSocial+' se perderán los datos aún no guardados.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelarEditarCliente} autoFocus>Cancelar</Button>
          <Button onClick={handleEditarCliente} >Aceptar</Button>
        </DialogActions>
      </Dialog>}
      {!contrato && (contratoId === 'nuevo') && <NuevoContratoModal open={nuevoModalOpen} templates={templates} setOpen={setNuevoModalOpen} nuevoContratoPara={nuevoContratoPara} />}
      {!!contrato && espacios
        ? <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} display="flex" flexDirection="row" alignItems="center">
              <TextField
                required
                fullWidth
                id="cliente"
                label="Cliente"
                name="cliente"
                error={!!clienteIncompleto}
                title={(cliente?.razonSocial ?? '') + (clienteIncompleto ? ' [ Incompleto !! ]' : '')}
                value={cliente?.razonSocial ?? ''}
                disabled={true}
              />
              {!!clienteIncompleto
                ? <IconButton title="Editar Cliente" color="error" onClick={ e => setPreEditarCliente(true)}><EditIcon /></IconButton>
                : <CheckIcon color="success" />}
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth error={!template} required>
                <InputLabel id="demo-simple-select-label">Template</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={!!template ? template.id : ''}
                  label="Template"
                  disabled={!editando}
                  onChange={(e) => {
                    cargarTemplate(e.target.value, (response) => {
                      setTemplate(response.data.template);
                    })
                  }}
                >
                  {templates.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.id} selected={!!template && template.id === item.id}>{item.nombre}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth error={!espacio} required>
                <InputLabel id="demo-simple-select-label">Espacio</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={espacio ?? ''}
                  label="Espacio"
                  disabled={!editando}
                  onChange={(e) => {
                    setEspacio(e.target.value)
                  }}
                >
                  {espacios.map((item, index) => {
                    return (item.activo || (!!espacio && !!contrato && (item.id === espacio.id) && !!contrato.id && !!contrato.aprobador)
                      ? <MenuItem key={index} value={item} selected={!!espacio && espacio.id === item.id}>{item.descripcion}</MenuItem>
                      : null
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                required
                fullWidth
                id="fechaDeAlta"
                label="Fecha de Alta"
                name="fechaDeAlta"
                value={contrato.fechaDeAlta ? moment(contrato.fechaDeAlta.date).format('DD/MM/YYYY') : ''}
                disabled={true}
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                autoComplete="given-name"
                name="numero"
                value={numero}
                required
                fullWidth
                id="numero"
                label="Numero"
                disabled={!editando}
                onChange={(e) => {
                  setNumero(e.target.value)
                }}

                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                autoComplete="given-name"
                inputMode="numeric"
                name="creditos"
                value={creditos}
                required
                fullWidth
                id="creditos"
                label="Creditos renovables"
                disabled={!editando}
                error={creditosSugeridos !== creditos}
                onChange={event => setCreditos(event.target.value ? parseFloat(event.target.value) : 0)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                autoComplete="given-name"
                name="valor"
                value={valor}
                inputMode="numeric"
                required
                fullWidth
                id="valor"
                label="Valor del contrato"
                disabled={!editando}
                onChange={event => setValor(parseFloat(event.target.value ? parseFloat(event.target.value) : 0))}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                autoComplete="given-name"
                name="moneda"
                value={moneda}
                inputMode="numeric"
                required
                fullWidth
                id="moneda"
                label="Moneda del contrato"
                disabled={!editando}
                onChange={event => setMoneda(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                autoComplete="given-name"
                inputMode="numeric"
                name="garantia"
                value={garantia}
                fullWidth
                id="garantia"
                label="Garantía"
                disabled={!editando}
                onChange={event => setGarantia(event.target.value ? parseFloat(event.target.value) : 0)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                autoComplete="given-name"
                inputMode="numeric"
                name="impresiones"
                value={impresiones}
                fullWidth
                id="impresiones"
                label="Impresiones"
                disabled={!editando}
                onChange={event => setImpresiones(event.target.value ? parseFloat(event.target.value) : 0)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                autoComplete="given-name"
                inputMode="numeric"
                name="visitas"
                value={visitas}
                fullWidth
                id="visitas"
                label="Visitas Simultáneas"
                disabled={!editando}
                onChange={event => setVisitas(event.target.value ? parseFloat(event.target.value) : 0)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                id="nombre"
                label="Nombre"
                name="nombre"
                value={nombre}
                disabled={!editando}
                autoComplete="family-name"
                onChange={(e) => {
                  setNombre(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                required
                fullWidth
                id="descripcion"
                label="Descripción"
                name="descripcion"
                value={descripcion}
                disabled={!editando}
                autoComplete="family-name"
                onChange={(e) => {
                  setDescripcion(e.target.value)
                }}
              />
            </Grid>

            {/** Membresias contratadas */}
            {// !viendoTemplate &&
              <Grid item xs={12} sm={4}>
                <Paper sx={{ width: '100%', mb: 2, padding: '14px' }}>
                  <Typography>Membresías contratadas</Typography>
                  <TableContainer>
                    <Table
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                    >
                      <TableHead>
                        <TableRow>
                          {columns_membresias.map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? 'right' : 'left'}
                              padding={headCell.disablePadding ? 'none' : 'normal'}
                              // sortDirection={puestosOrderBy === headCell.id ? order : false}
                            >
                              {/* <TableSortLabel
                                active={puestosOrderBy === headCell.id}
                                direction={puestosOrderBy === headCell.id ? order : 'asc'}
                                // onClick={createPuestosSortHandler(headCell.id)}
                              > */}
                                {headCell.label}
                                {/* {orderBy === headCell.id ? (
                                  <Box component="span" >
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </Box>
                                ) : null} */}
                              {/* </TableSortLabel> */}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {puestos
                          .map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.modo_de_contratacion_id}
                                >
                                <TableCell
                                  sx={{paddingBlock: '10px'}}
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {row.nombre}
                                </TableCell>
                                <TableCell align="left" padding="none">
                                  <TextField
                                    name="puestos"
                                    type='number'
                                    InputProps={{inputProps: { min: 0 }}}
                                    value={row.puestos}
                                    id="puestos"
                                    disabled={false}
                                    size="small"
                                    variant="standard"
                                    sx={{ width: '40px' }}
                                    onChange={(event) => handlePuestosChange(row, event.target.value)}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {row.creditos}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                      <TableFooter>
                        <TableRow sx={{backgroundColor: 'black'}}>
                          <TableCell padding="none" sx={{padding: '10px'}} ><Typography fontWeight='bold' color='white'>Totales</Typography></TableCell>
                          <TableCell padding="none"><Typography fontWeight='bold' color='white'>{puestos.reduce((anterior, item) => anterior + parseInt(!!item.puestos ? item.puestos : 0), 0)}</Typography></TableCell>
                          <TableCell padding="none"><Typography fontWeight='bold' color='white'>{creditosSugeridos}</Typography></TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            }

            {/** Ubicaciones designadas */}
            {// !viendoTemplate &&
              <Grid item xs={12} sm={8}>
                <Paper sx={{ width: '100%', mb: 2, padding: '14px' }}>
                  <Stack display='flex' flexDirection='row' alignItems='center'>
                    <Typography>Ubicaciones designadas</Typography>
                    {!!editando && !agregandoUbicacion && <Button variant="text" onClick={() => { setAgregandoUbicacion(true) }}>Asignar</Button>}
                  </Stack>
                  {!!agregandoUbicacion && <Stack justifyContent='space-evenly' padding='10px' display='flex' flexDirection='row' alignItems='center'>
                    <FormControl fullWidth error={!espacioParaUbicacion} required>
                      <InputLabel id="demo-simple-select-label">Espacio</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={espacioParaUbicacion ?? ''}
                        label="Espacio"
                        onChange={(e) => {
                          setEspacioParaUbicacion(e.target.value)
                        }}
                      >g
                        {espacios.map((item, index) => {
                          return (item.activo || (!!espacio && !!contrato && (item.id === espacio.id) && !!contrato.id && !!contrato.aprobador)
                            ? <MenuItem key={index} value={item} selected={!!espacioParaUbicacion && espacioParaUbicacion.id === item.id}>{item.descripcion}</MenuItem>
                            : null
                          )
                        })}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth error={!ubicacion} required>
                      <InputLabel id="demo-simple-select-label">Ubicación</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ubicacion ?? ''}
                        label="Ubicación"
                        disabled={!ubicacionesDelEspacio || (ubicacionesDelEspacio.length === 0)}
                        onChange={(e) => {
                          setUbicacion(e.target.value)
                        }}
                      >
                        {ubicacionesDelEspacio.map((item, index) =>
                          <MenuItem key={index} value={item} selected={!!ubicacion && ubicacion.id === item.id}>{item.tipo + ' - ' + item.sector.nombre + ' - ' + item.nombre}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <IconButton color="success" disabled={!ubicacion} onClick={handleAgregarUbicacion}><CheckIcon /></IconButton>
                    <IconButton color="error" onClick={handleCancelarUbicacion}><DeleteIcon /></IconButton>
                  </Stack>}
                  <TableContainer>
                    <Table
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                    >
                      <TableHead>
                        <TableRow>
                          {columns_ubicaciones.map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? 'right' : 'left'}
                              padding={headCell.disablePadding ? 'none' : 'normal'}
                              // sortDirection={ubicacionesOrderBy === headCell.id ? order : false}
                            >
                              {/* <TableSortLabel
                                active={ubicacionesOrderBy === headCell.id}
                                direction={ubicacionesOrderBy === headCell.id ? order : 'asc'}
                                // onClick={createUbicacionesSortHandler(headCell.id)}
                              > */}
                                {headCell.label}
                                {/* {orderBy === headCell.id ? (
                                  <Box component="span" >
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </Box>
                                ) : null} */}
                              {/* </TableSortLabel> */}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                            puestos.slice().sort(getComparator(order, orderBy)) */}
                        {ubicaciones.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                // onClick={(event) => openItem(event, row.id)}
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                              >
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {row.espacio.nombre}
                                </TableCell>
                                <TableCell align="left">{row.sector.nombre}</TableCell>
                                <TableCell align="left">{row.tipo}</TableCell>
                                <TableCell align="left">{row.nombre}</TableCell>
                                <TableCell align="left">{row.puestosDisponibles}</TableCell>
                                {!!editando && <IconButton color="error" onClick={() => handleQuitarUbicacion(row)}><DeleteIcon /></IconButton>}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            }

            {/** Vigencia y aprobación del contrato */}
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Vigencia desde"
                  inputFormat="DD/MM/YYYY"
                  value={vigenciaDesde}
                  onChange={handleChangeVigenciaDesde}
                  renderInput={(params) => <TextField {...params} />}
                  disabled={!editando}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} alignItems='center' display='flex'>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Vigencia hasta"
                  inputFormat="DD/MM/YYYY"
                  minDate={vigenciaDesde}
                  value={vigenciaHasta}
                  onChange={handleChangeVigenciaHasta}
                  renderInput={(params) => <TextField {...params} />}
                  disabled={!editando}
                />
              </LocalizationProvider>
              {!!contrato && !contrato.vigenciaHasta && !!vigenciaHasta && <IconButton onClick={() => setVigenciaHasta(null)}><DeleteIcon /></IconButton>}
            </Grid>


            {
            // Inputs de Variables
              (variablesConInput.length > 0) &&
              variablesConInput.map(variable => <Grid item xs={12} sm={6}>

                <TextField
                  name={variable.nombre}
                  value={variable.value}
                  fullWidth
                  id={variable.nombre}
                  multiline={variable.tipo === 'textarea'}
                  rows={variable.tipo === 'textarea' ? 3 : 1}
                  label={variable.descripcion}
                  required
                  disabled={!editando}
                  error={!variable.value}
                  onChange={(e) => handleVariable(variable, e.target.value)}
                />
              </Grid>)
            }


            <Grid item xs={12} sm={4}>
              <TextField
                autoComplete="given-name"
                name="aprobadoPor"
                value={aprobadoPor}
                fullWidth
                id="aprobadoPor"
                label="Aprobado por:"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="aprobador"
                value={!!fechaDeAprobacion ? moment(fechaDeAprobacion.date).format('DD/MM/YYYY') : ''}
                fullWidth
                id="fechaDeAprobacion"
                label="Fecha:"
                disabled={true}
              />
            </Grid>

            {!fechaDeAprobacion && <Grid item xs={12} sm={4}>
              <Button variant="outlined" title="Aprobar" endIcon={<CheckIcon />} disabled={!editando} onClick={handleAprobarContrato}>Aprobar</Button>
            </Grid>}

            {(!!template && !!contrato && !!contrato.id && !!contrato.aprobador) &&
              (!documentoURL
              ? <Grid item xs={12} sm={4}>
                <Button variant="contained" title="Generar PDF" endIcon={<InsertDriveFile />} onClick={handleGenerarPDF}>Generar PDF</Button>
              </Grid>
              : <Grid item xs={12} sm={4}>
                <Button variant="contained" title="Ver PDF" endIcon={<Visibility />} onClick={handleVerPDF}>Ver PDF</Button>
              </Grid>)
            }
{/*
            {!!documentoURL && !!template && !!contrato && !!contrato.id && !!contrato.aprobador &&
              <Grid item xs={12} sm={4}>
                <Button variant="contained" title="Generar PDF" endIcon={<InsertDriveFile />} onClick={handleGenerarPDF}>Generar PDF</Button>

              </Grid>
            }
 */}
            {!documentoURL && !!template && <Grid item xs={12} sm={12}>
              <Button variant="outlined" title={(viendoTemplate ? "Ocultar" : "Ver") + " Template"} endIcon={<InsertDriveFile />} onClick={handleVerPreview}>{(viendoTemplate ? "Ocultar" : "Ver") + " Template"}</Button>
            </Grid>}


            {/** WYSIWYGEditor */}
{/*
            {!!viendoTemplate &&
              <Grid item xs={12} sm={12}>
                {!!template && <Box padding="14px">
                  // {!editando && <Button variant="outlined" title="Editar" onClick={editarContrato} >Editar</Button>}
                  <WYSIWYGEditor contrato={contratoParaDocumento} variablesConInput={variablesConInput} editorReadOnly={true} docURL={template.template_file} templateBody={template.body} />
                </Box>}
              </Grid>
            }
 */}
            {/** Acciones */}
            <Grid item xs={12} sm={12}>
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                {editando &&<Button variant="contained" onClick={handleGuardarContrato}>Guardar cambios</Button>}
                {editando && <Button variant="contained" onClick={() => { navigate('/contratos'); }}>Cancelar cambios</Button>}
                <Button variant="contained" onClick={handleRenovarContrato}>Renovar contrato</Button>
              </Stack>
            </Grid>

            {!!viendoTemplate && !!documentoContent &&
              <Grid item sm={12}>
                <Paper elevation={3} sx={{ padding: '24px' }} dangerouslySetInnerHTML={{ __html: documentoContent }} />
              </Grid>
            }

          </Grid>

        </Box>

        : <></>
      }
    </Paper>
  )
}

export default Contrato;