import { Box, Button, Paper, Typography } from '@mui/material';
import { ContentState, convertFromRaw, convertToRaw, EditorState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { Component, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import APIManager from '../../lib/apiManager';
import CustomEditorButton from './components/CustomEditorButton';
import { Stack } from '@mui/material';
import { SupervisedUserCircle } from '@mui/icons-material';
import moment from 'moment';
import { variableAplicada } from '../../lib/templateFunctions';

const WYSIWYGEditor = ({docURL, templateBody, variablesConInput, editorReadOnly, contrato, onSaveContent, onCancelar, onStateChange}) => {

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [botonera, setBotonera] = useState([]);
  const [variables, setVariables] = useState([]);

  const cambioDeEstado = (estado) => {
    setEditorState(estado);
    if (!!onStateChange) {
      onStateChange(estado);
    }
  }

  const cargarHTML = () => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', process.env.REACT_APP_MEDIA_URL+'/'+docURL, true);
    xhr.onreadystatechange= function() {
        if (this.readyState!==4) return;
        if (this.status!==200) return; // or whatever error handling you want
      const html = this.responseText;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    };
    xhr.send();
  }

  const cargarEstado = () => {
    var xhr= new XMLHttpRequest();
    xhr.open('GET', process.env.REACT_APP_MEDIA_URL+'/'+docURL, true);
    xhr.onreadystatechange= function() {
      if (this.readyState!==4) return;
      if (this.status!==200) return; // or whatever error handling you want

      // Vino html
      actualizarEstadoFromHTML(this.responseText);

      // Vino JSON
      // const rawContent = JSON.parse(this.responseText);
      // if (rawContent) {
      //   const contentState = convertFromRaw(rawContent);
      //   const editorState = EditorState.createWithContent(contentState);
      //   setEditorState(editorState);
      // }
    };
    xhr.send();
  }

  const guardarEstado = () => {
    const content = editorState.getCurrentContent();
    const rawContent = convertToRaw(content);
    const markup = draftToHtml(rawContent);
    // console.log(markup);
    onSaveContent(markup);

    // Llamar a la API para guardar el content JSON stringified
    // console.log(JSON.stringify(rawContent));
    // onSaveContent(JSON.stringify(rawContent));
  }

  const cancelar = () => {
    if (!!templateBody) {
      const html = templateBody;
      actualizarEstadoFromHTML(html);
    } else {
      // Es nuevo?
      setEditorState(EditorState.createEmpty());
    }
    onCancelar();
  }

  const actualizarEstadoFromHTML = (html) => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }

  const CustomButton = ({onChange, editorState}) => {
    const action = () => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        '%FECHA_DE_HOY%',
        editorState.getCurrentInlineStyle(),
      );
      onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    };

    return (
      <div onClick={action}>HOY</div>
    );
  }

  const FirmaJMOButton = ({ onChange, editorState }) => {
    const action = () => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        'firmaJMO',
      );
      onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    };

    return (
      <Box className='rdw-option-wrapper' color='red' title='firma JMO' onClick={action}><SupervisedUserCircle/></Box>
    );
  }

  const botoneraDeVariables = () => {
    APIManager.getList('variablesDeContratos', {}, (response) => {
      if (response.success) {
        setVariables(response.data.listado);
      }
    });
  }

/*
  const variableAplicada = (html, variable, contrato) => {
    const replaceText = '%' + variable.variable + '%';
    let valor = replaceText;
    switch (variable.nombre) {
      case 'fechaDeHoy':
        valor = moment(new Date);
        valor.locale('es');
        valor = valor.format('LL');
        break;
      default:
        const partes = variable.nombre.split('.');
        if ((partes.length === 1) && !!Object.entries(contrato)[variable.nombre]) {
          valor = Object.entries(contrato)[variable.nombre];
        } else if (!!Object.entries(contrato)[partes[0]]) {
          valor = Object.entries(contrato)[partes[0]];
          valor = Object.entries(valor)[partes[1]];
        }
        break;
    }
    // return html.replace(new RegExp(`^${replaceText}`, 'g'), valor);
    return html.replace(replaceText, valor);
  }
 */

  useEffect(() => {
    if (variables.length > 0) {
      const botones = variables.map((variable) => {
        const button = <CustomEditorButton variable={variable} />;
        return button;
      });
      setBotonera([...botones, <FirmaJMOButton />]);

      if (!!contrato) {
        // Tenemos variables y contrato! Hay que parsear el documento
        let html = templateBody;
        variables.forEach(variable => {
          const variableConInput = variablesConInput.find(item => item.nombre === variable.nombre);
          if (!!variableConInput) {
            variable.value = variableConInput.value;
          }
          html = variableAplicada(html, variable, contrato);
        });
        actualizarEstadoFromHTML(html);
      }

    }

  }, [variables]);

  useEffect(() => {
    // console.log('WYSIWYG Editor', templateBody, contrato);
    if (!!templateBody) {
      // Viene en html
      const html = templateBody;
      actualizarEstadoFromHTML(html);
      // Viene en raw
      // const rawContent = JSON.parse(template.body);
      // setEditorState(convertFromRaw(rawContent));
    } else if (!!docURL) {
      cargarEstado();
    }
    botoneraDeVariables();
  }, []);

  return (
    <Box>
      {/* <Button onClick={cargarHTML}>Abrir</Button> */}
      {!editorReadOnly && <Stack spacing={2} direction="row">
        <Button variant="outlined" onClick={guardarEstado}>Guardar</Button>
        <Button variant="outlined" onClick={cancelar}>Cancelar</Button>
      </Stack>}
      <Paper sx={{ marginTop: "14px" }}>
        {/* !!botonera && */
          <Editor
            editorClassName="editor-container"
            toolbarHidden={editorReadOnly}
            readOnly={editorReadOnly}
            editorState={editorState}
            onEditorStateChange={cambioDeEstado}
            toolbarCustomButtons={botonera}
          />
        }
      </Paper>
    </Box>
  )
}

export default WYSIWYGEditor;