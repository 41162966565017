import { useEffect, useState } from 'react';
import { Card, FormControl, Grid, Input, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import APIManager from '../../../../lib/apiManager';

const OnboardingCard = ({ espacios, espacio, membresias, membresia, enabled, onChangeMembresia, onChangeEspacio }) => {

  return <Card raised={true} sx={{ padding: '14px' }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize="large">Datos de OnBoarding</Typography>
      </Grid>
      {!!espacios && <Grid item xs={12} sm={4}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="espacio-select-label">Espacio</InputLabel>
          <Select
            labelId="espacio-select-label"
            id="espacio-select"
            value={espacio}
            label="Espacio"
            disabled={!enabled}
            autoWidth
            onChange={(e) => {
              onChangeEspacio(e.target.value)
            }}
          >
            {espacios.map((espacio, index) => {
              return (
                <MenuItem value={espacio}>{espacio.descripcion}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>}
      {!!membresias && <Grid item xs={12} sm={8}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="membresia-select-label">Membresía</InputLabel>
          <Select
            labelId="membresia-select-label"
            id="membresia-select"
            value={membresia ?? ''}
            error={!membresia}
            label="Membresía"
            disabled={!enabled}
            autoWidth
            onChange={(e) => {
              onChangeMembresia(e.target.value)
            }}
          >
            {membresias.map((membresia, index) => {
              return (
                <MenuItem value={membresia}>{membresia.descripcion}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>}
    </Grid>
  </Card>
}

export default OnboardingCard;