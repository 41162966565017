import { EditorState, Modifier } from 'draft-js';
import { ChevronRight } from '@mui/icons-material';
import { ChevronLeft } from '@mui/icons-material';
import { BorderStyle, Code } from '@mui/icons-material';
import { Box, Icon, Typography } from '@mui/material';

const CustomEditorButton = ({ onChange, editorState, variable }) => {
  const action = () => {
    // const contentState = Modifier.replaceText(
    //   editorState.getCurrentContent(),
    //   editorState.getSelection(),
    //   '%'+variable.variable+'%',
    //   editorState.getCurrentInlineStyle(),
    // );

    const inlineStyle = ['color: '+'green'];

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '%'+variable.variable+'%',
      inlineStyle,
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  return (
    <Box className='rdw-option-wrapper' color={variable.color} bgcolor={variable.bgcolor} title={variable.descripcion} onClick={action}><ChevronLeft/><Typography>{variable.descripcion}</Typography><ChevronRight/></Box>
  );
}

export default CustomEditorButton;