import { login } from '../lib/auth';
import Usuario from './Usuario';
export default class Sistema {
  constructor() {
    this.espacio = null;
    this.espacios = [];
    this.actual = localStorage.getItem('mimqcoBoActual') ? JSON.parse(localStorage.getItem('mimqcoBoActual')) : {};
    this.usuario = null;
    this.oficinas = null;
  }

  setEspacioID(espacioID) {
    if (this.espacios.length > 0) {
      this.espacios.forEach((espacio) => {
        if (espacio.id === espacioID) {
          this.espacio = espacio;
        }
      })
    }
  }

  saveActual() {
    localStorage.setItem('mimqcoBoActual', JSON.stringify(this.actual));
  }

  loadActual() {
    this.actual = JSON.parse(localStorage.getItem('mimqcoBoActual'));
  }

  refreshUser(setUsuario) {
    login((usuarioData) => {
      this.usuario = new Usuario(usuarioData);
      this.space_id = usuarioData.space_id;
      this.oficinas = usuarioData.oficinas;
      if (setUsuario) {
        setUsuario(this.usuario);
      }
    });
  }

}

export const sistema = new Sistema();