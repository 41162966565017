import { sendRequest, getUIData } from './serverAPI';

export default class APIManager {
  static getList(lista, data = {}, successCallback, errorCallback) {
    data.lista = lista;
    sendRequest('post','query','getList', data, (response) => {
      if (response.success && successCallback) {
        return successCallback(response);
      }
      if (!response.success && errorCallback) {
        return errorCallback(response);
      }
    })
  }

  static getEspacios(successCallback, errorCallback) {
    this.getList('espacios', {activos: true}, (response) => successCallback(response.data.listado), errorCallback);
  }

  static getSectores(espacio, successCallback, errorCallback) {
    this.getList('sectores', {espacioID: espacio.id, conCupoEscritorio: true}, (response) => successCallback(response.data.listado), errorCallback);
  }

  static getSalasConReservas(espacio, fecha, successCallback, errorCallback) {
    getUIData('getSalasConReservas',{espacioID: espacio.id, fecha: fecha}, successCallback, errorCallback);
  }

  static getCode(tipo, callback) {
    getUIData('getCodigo', {tipo: 'miembro'}, callback);
  }

  static sendRequest = (tipo, endpoint, accion, data, callback) => {
    return sendRequest(tipo, endpoint, accion, data, callback);
  }
}
