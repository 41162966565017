import { Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIManager from '../../lib/apiManager';
import { sistema } from "../../model/Sistema";
import moment, { now } from "moment";
import { useInterval } from "../../lib/useInterval";

const ControlDeAsistencia = ({setBotonera, espacio}) => {

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('fecha');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);

  const [active, setActive] = useState(false);
  const [nombre, setNombre] = useState('');
  const [fechaDesde, setFechaDesde] = useState(moment(new Date).format('YYYY-MM-DD'));
  const [fechaHasta, setFechaHasta] = useState(moment(new Date).format('YYYY-MM-DD'));

  const [ultimaConsulta, setUltimaConsulta] = useState(null);
  const [consultando, setConsultando] = useState(false);
  const intervaloConsulta = 10000; // Tiempo de intervalo para actualizar (milisegundos)

  const navigate = useNavigate();

  const columns = [
    {
      id: 'fecha',
      numeric: false,
      disablePadding: false,
      label: 'Fecha',
    },
    // {
    //   id: 'hora',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Hora',
    // },
    {
      id: 'descripcion',
      numeric: false,
      disablePadding: true,
      label: 'Descripción',
    },
    {
      id: 'success',
      numeric: false,
      disablePadding: true,
      label: 'Habilitado',
    },
  ];

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const openItem = (event, id) => {
    navigate('/controlDeAsistencia/' + id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const actualizarItems = () => {
    setConsultando(true);
    // setRows([]);
    if (!!espacio) {
      APIManager.getList('asistencias', {fechaDesde: fechaDesde, fechaHasta: fechaHasta, espacio_id: espacio.id, nombre: nombre}, (response) => {
        if (response.success) {
          setRows(response.data.listado);
          setUltimaConsulta(now());
          setConsultando(false);
        }
      })
    }
  }

  const toggleActive = (event) => {
    setActive(!active);
  }

  const handleChangeNombre = (event) => {
    setNombre(event.target.value);
  }

  const handleChangeFechaDesde = (event) => {
    setFechaDesde(event.target.value);
  }

  const handleChangeFechaHasta = (event) => {
    setFechaHasta(event.target.value);
  }

  useEffect(() => {
    actualizarItems();
    sistema.actual.active = active;
    setBotonera([
      { type: 'input', id: 'input-nombre', inputType: 'text', title: 'Nombre de cliente', label: 'Nombre', value: nombre, onChange: handleChangeNombre },
      { type: 'input', id: 'input-fechaDesde', inputType: 'date', title: 'Límite inferior de fecha', label: 'Fecha desde', value: fechaDesde, onChange: handleChangeFechaDesde },
      { type: 'input', id: 'input-fechaHasta', inputType: 'date', title: 'Límite superior de fecha', label: 'Fecha hasta', value: fechaHasta, onChange: handleChangeFechaHasta },
    ]);
  }, [active, nombre, fechaDesde, fechaHasta, espacio]);

  useInterval(() => {
    console.log('intervalo - consultando: ', consultando);
    if (!consultando) {
      actualizarItems();
    }
  }, intervaloConsulta);

  return (
    <Box>
      <Paper sx={{ width: '100%'}}>
        <Typography padding={2} fontWeight="bold" fontSize="large">Lista de Asistencias</Typography>
        <TableContainer sx={{maxHeight: 'calc(100vh - 300px)'}}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell> */}
                {columns.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.cliente_id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => openItem(event, row.cliente_id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.fecha}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          // onClick={(event) => handleClick(event, row.cliente_id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                      <TableCell align="left">{new Date(row.fecha).toLocaleDateString()}
                        {/* </TableCell> */}
                        {' '}
                        {/* <TableCell align="left"> */}
                          {new Date(row.fecha).toLocaleTimeString()}</TableCell>
                      <TableCell
                        // component="th"
                        // id={labelId}
                        // scope="row"
                        padding="none"
                      >
                        {row.descripcion}
                      </TableCell>
                      <TableCell
                        // component="th"
                        // id={labelId}
                        // scope="row"
                        padding="none"
                      >
                        {row.success ? 'SI' : 'NO' }
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </Box>
  );
}

export default ControlDeAsistencia;