export default class Usuario {
  constructor(data) // v2
  {
    if (!data) {
      data = {};
    }
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });

    this.emailDeContacto = data.contact_email;
    this.nombreCompleto = this.nombres + (this.apellidos ? ' ' + this.apellidos : '');
    this.descripcion = this.nombreCompleto + ' (' + this.emailDeContacto + ')';
    this.avatar = data.imagen;
    this.aspectosProfesionales = data.professional_aspects;
    this.professionalKeywords = data.professional_keywords;
    this.professionalHighlights = data.professional_highlights;

  }

  static newFromV1Data(data) // v1
  {
    const result = new this();
    if (!data) {
      data = {};
    }
    result.id = data.id;
    result.email = data.email;
    result.nombres = data.first_name;
    result.apellidos = data.last_name;
    result.emailDeContacto = data.contact_email;
    result.nombreCompleto = result.nombres + (result.apellidos ? ' ' + result.apellidos : '');
    result.descripcion = result.nombreCompleto + ' (' + result.emailDeContacto + ')';
    result.avatar = data.profile_picture;
    result.profile_photo = data.profile_picture;
    result.creditos = data.credits;
    result.membresia = data.membresia;
    result.contrato = data.contrato;
    result.status = data.status;
    result.tieneOficina = data.tieneOficina;
    result.aspectosProfesionales = data.professional_aspects;
    result.aptitudes = data.aptitudes;

    return result;
  }

}
