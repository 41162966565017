import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import APIManager from '../../lib/apiManager';

const Clausula = () => {
  const [clausula, setClausula] = useState(null);
  let { clausulaId } = useParams();

  useEffect(() => {
    APIManager.sendRequest('post', 'contratos', 'getClausula', {id: clausulaId}, (response) => {
      console.log(response);
      if (response.success) {
        setClausula(response.data.clausula);
      }
    })
  }, []);

  return <div>{ !!clausula && clausula.titulo }</div>;
}

export default Clausula;