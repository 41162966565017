import { useEffect, useState } from 'react';
import { Card, FormControl, Grid, Input, InputLabel, Typography } from '@mui/material';
import UsuarioCard from '../UsuarioCard';

const EmpresaCard = ({ empresa, enabled, onChange }) => {
  const [nombre, setNombre] = useState(empresa.nombre);
  const [descripcion, setDescripcion] = useState(empresa.descripcion);
  // const [razonSocial, setRazonSocial] = useState(empresa.razonSocial);
  // const [cuit, setCuit] = useState(empresa.cuit);
  const [usuarioPrincipal, setUsuarioPrincipal] = useState(empresa.usuarioPrincipal);

  const itemActualizado = () => {
    const nuevoItem = Object.assign({}, empresa);
    nuevoItem.nombre = nombre;
    // nuevoItem.razonSocial = razonSocial;
    // nuevoItem.cuit = cuit;
    nuevoItem.descripcion = descripcion;
    nuevoItem.usuarioPrincipal = usuarioPrincipal;

    return nuevoItem;

  }

  const handleNombre = (event) => {
    setNombre(event.target.value);
  }

  // const handleRazonSocial = (event) => {
  //   setRazonSocial(event.target.value);
  // }

  const handleDescripcion = (event) => {
    setDescripcion(event.target.value);
  }
  // const handleCuit = (event) => {
  //   setCuit(event.target.value);
  // }

  const handleUsuario = (usuario) => {
    setUsuarioPrincipal(usuario);
  }

  useEffect(() => {
    if ((nombre !== empresa.nombre)
      // || (razonSocial !== empresa.razonSocial)
      || (descripcion !== empresa.descripcion)
      // || (cuit !== empresa.cuit)
      || (usuarioPrincipal !== empresa.usuarioPrincipal)
    )
    onChange(itemActualizado());
  // }, [nombre, razonSocial, descripcion, cuit, usuarioPrincipal]);
  }, [nombre, descripcion, usuarioPrincipal]);

  return <Card raised={true} sx={{ padding: '14px' }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize="large">Datos de la Empresa</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="nombre-label" >Nombre</InputLabel>
          <Input
            disabled={!enabled}
            labelId="nombre-label"
            id="nombre-input"
            value={nombre}
            onChange={handleNombre}
            required
          >
          </Input>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <FormControl variant="standard">
          <InputLabel id="razonSocial-label" >Razon Social</InputLabel>
          <Input
            disabled={!enabled}
            labelId="razonSocial-label"
            id="razonSocial-input"
            value={razonSocial}
            onChange={handleRazonSocial}
            required
          >
          </Input>
        </FormControl>
      </Grid> */}
      <Grid item xs={12} sm={8}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="descripcion-label" >Descripcion</InputLabel>
          <Input
            disabled={!enabled}
            labelId="descripcion-label"
            id="descripcion-input"
            value={descripcion}
            onChange={handleDescripcion}
          >
          </Input>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} sm={4}>
        <FormControl variant="standard">
          <InputLabel id="cuit-label" >CUIT</InputLabel>
          <Input
            disabled={!enabled}
            labelId="cuit-label"
            id="cuit-input"
            value={cuit}
            onChange={handleCuit}
            required
          >
          </Input>
        </FormControl>
      </Grid> */}
      {!!empresa.usuarioPrincipal &&
        <Grid item xs={12} mt={2}>
          <UsuarioCard enabled={enabled} usuario={usuarioPrincipal} onChange={handleUsuario} />
        </Grid>
      }
    </Grid>
  </Card>
}

export default EmpresaCard;