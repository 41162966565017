const menuJSON =
  {
    "0": {
        "id": null,
        "nombre": "asistencia",
        "descripcion": "Control de asistencia",
        "cssClass": "menu-button"
    },
    "1": {
        "id": null,
        "nombre": "pendientes",
        "descripcion": "Miembros pendientes",
        "cssClass": "menu-button"
    },
    "2": {
        "id": null,
        "nombre": "separador",
        "descripcion": null,
        "cssClass": "separador"
    },
    "3": {
        "id": null,
        "nombre": "usuarios",
        "descripcion": "Miembros",
        "cssClass": "menu-button"
    },
    "4": {
        "id": null,
        "nombre": "reservas",
        "descripcion": "Reservas",
        "cssClass": "menu-button"
    },
    "5": {
        "id": null,
        "nombre": "empresas",
        "descripcion": "Empresas",
        "cssClass": "menu-button"
    },
    "6": {
        "id": null,
        "nombre": "clientes",
        "descripcion": "Clientes",
        "cssClass": "menu-button"
    },
    // "6": {
    //     "id": null,
    //     "nombre": "separador",
    //     "descripcion": null,
    //     "cssClass": "separador"
    // },
    "7": {
        "id": null,
        "nombre": "creditos",
        "descripcion": "Creditos",
        "cssClass": "menu-button"
    },
    "8": {
        "id": null,
        "nombre": "notificaciones",
        "descripcion": "Notificaciones",
        "cssClass": "menu-button"
    },
    "9": {
        "id": null,
        "nombre": "paquetes",
        "descripcion": "Paquetes de Creditos",
        "cssClass": "menu-button"
    },
    "10": {
        "id": null,
        "nombre": "pedidos",
        "descripcion": "Pedidos de Creditos",
        "cssClass": "menu-button"
    },
    "11": {
        "id": null,
        "nombre": "transacciones",
        "descripcion": "Transacciones",
        "cssClass": "menu-button"
    },
    "12": {
        "id": null,
        "nombre": "separador",
        "descripcion": null,
        "cssClass": "separador"
    },
    "13": {
        "id": null,
        "nombre": "contratos",
        "descripcion": "Contratos",
        "cssClass": "menu-button"
    },
    "14": {
        "id": null,
        "nombre": "modosDeContratacion",
        "descripcion": "Modos de Contratación",
        "cssClass": "menu-button"
    },
    "15": {
        "id": null,
        "nombre": "separador",
        "descripcion": null,
        "cssClass": "separador"
    },
    "16": {
        "id": null,
        "nombre": "beneficios",
        "descripcion": "Beneficios",
        "cssClass": "menu-button"
    },
    "17": {
        "id": null,
        "nombre": "eventos",
        "descripcion": "Experiencias",
        "cssClass": "menu-button"
    },
    "18": {
        "id": null,
        "nombre": "espacios",
        "descripcion": "Espacios",
        "cssClass": "menu-button"
    },
    "19": {
        "id": null,
        "nombre": "staff",
        "descripcion": "Staff",
        "cssClass": "menu-button"
    },
    "20": {
        "id": null,
        "nombre": "separador",
        "descripcion": null,
        "cssClass": "separador"
    },
    "21": {
        "id": null,
        "nombre": "salas",
        "descripcion": "Salas",
        "cssClass": "menu-button"
    },
    "22": {
        "id": null,
        "nombre": "hotdesk",
        "descripcion": "HOTDESK",
        "cssClass": "menu-button"
    },
    "23": {
        "id": null,
        "nombre": "sectores",
        "descripcion": "Sectores",
        "cssClass": "menu-button"
    },
    "24": {
        "id": null,
        "nombre": "oficinas",
        "descripcion": "Oficinas",
        "cssClass": "menu-button"
    },
    "25": {
        "id": null,
        "nombre": "escritoriosExclusivos",
        "descripcion": "Escritorios Exclusivos",
        "cssClass": "menu-button"
    },
    "26": {
        "id": null,
        "nombre": "clausulas",
        "descripcion": "Clausulas",
        "cssClass": "menu-button"
    },
    "27": {
        "id": null,
        "nombre": "templates",
        "descripcion": "Templates",
        "cssClass": "menu-button"
    },
    "28": {
        "id": null,
        "nombre": "clientesExcluidos",
        "descripcion": "Clientes Excluidos",
        "cssClass": "menu-button"
    },
};

const menuDisponible = ['templates', 'contratos','clientes', 'clientesExcluidos','asistencia','espacios','separador'];
// const menuDisponible = ['clientes','asistencia'];

export const menuFunction = (menu) => {
  return Object.values(menu).filter((item) => menuDisponible.includes(item.nombre));
};