import { Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, Input, InputLabel, Link, MenuItem, Modal, Paper, Select, TableFooter, TextField, Typography } from "@mui/material";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APIManager from '../../lib/apiManager';
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Stack } from '@mui/material';
import { Check as CheckIcon, Delete as DeleteIcon, InsertDriveFile, Visibility } from "@mui/icons-material";
import { sistema } from '../../model/Sistema';

const Espacio = ({setBotonera}) => {

  const [espacio, setEspacio] = useState(null);
  const [editando, setEditando] = useState(false);
  const [procesando, setProcesando] = useState(false);

  const [nombre, setNombre] = useState('');
  const [direccion, setDireccion] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [razonSocial, setRazonSocial] = useState('');
  const [cuit, setCuit] = useState('');
  const [responsable, setResponsable] = useState('');
  const [caracter, setCaracter] = useState('');
  const [email, setEmail] = useState('');
  const [firmaURL, setFirmaURL] = useState(null);
  const [firmaURLFull, setFirmaURLFull] = useState(null);
  const [activo, setActivo] = useState(false);

  let { espacioId } = useParams();

  const [hayCambios, setHayCambios] = useState(false);
  const [espacioAEnviar, setEspacioAEnviar] = useState(null);

  const navigate = useNavigate();

  const nuevoEspacio = {
    id: null,
    nombre: '',
    direccion: '',
    horario: { apertura: '08:00:00', cierre: '20:30:00', semana: [false, true, true, true, true, true, false]},
    activo: false,
    caracter: '',
    cuit: '',
    email: '',
    ciudad: '',
    firmaURL: null,
    firmaURLFull: null,
    razonSocial: '',
    responsable: '',
  }

  const editarContrato = () => {
    setEditando(!editando);
  }

  const espacioActualizado = () => {
    const espacioActualizado = { ...espacio };
    espacioActualizado.activo = activo;
    espacioActualizado.nombre = nombre;
    espacioActualizado.direccion = direccion;
    espacioActualizado.ciudad = ciudad;
    espacioActualizado.cuit = cuit;
    espacioActualizado.razonSocial = razonSocial;
    espacioActualizado.responsable = responsable;
    espacioActualizado.firmaURL = firmaURL;
    espacioActualizado.caracter = caracter;

    return espacioActualizado;

  }

  const guardarEspacio =  () => {
    // const espacioAEnviar = espacioActualizado();
    APIManager.sendRequest('post', 'espacios', 'modificarEspacio', {espacio: espacioAEnviar}, response => {
      setEditando(false); // Se guardó y ya no estamos editando
      setEspacio(espacioAEnviar);
    });
  }

  const cancelarEdicion = () => {
    setEditando(false);
    setNombre(espacio.nombre);
    setDireccion(espacio.direccion)
  }

  useEffect(() => {
    if (!!espacioId && isNaN(espacioId) && (espacioId === "nuevo")) {
      // Es un espacio nuevo
      setEspacio(nuevoEspacio);
    } else {
      // Es un contrato existente
      APIManager.sendRequest('post', 'espacios', 'getEspacio', { id: espacioId, conAdministradores: true, conEscritorios: false, conSalas: false }, response => {
        if (response.success) {
          setEspacio(response.data.espacio);
        }
      });
    }
  }, [espacioId]);

  useEffect(() => {
    if (!!espacio) {
      sistema.actual.espacio = espacio;
      setActivo(espacio.activo);
      setNombre(espacio.nombre);
      setCiudad(espacio.ciudad);
      setDireccion(espacio.direccion);
      setCuit(espacio.cuit);
      setRazonSocial(espacio.razonSocial);
      setResponsable(espacio.responsable);
      setCaracter(espacio.caracter);
      setFirmaURL(espacio.firmaURL);
      setFirmaURLFull(espacio.firmaURLFull);
    }
  }, [espacio]);

  useEffect(() => {
    setHayCambios(true);
    setEspacioAEnviar(espacioActualizado());
  }, [nombre, direccion, cuit, razonSocial, responsable, ciudad, activo, caracter]);

  useEffect(() => {
    setBotonera([
      { title: 'Volver', label: 'Volver', variant: 'outlined', onClick: () => { navigate(-1); } },
      { title: 'Editar', label: 'Editar', variant: 'contained', visible: !editando, onClick: () => { setEditando(true) } },
      { title: 'Guardar', label: 'Guardar', variant: 'contained', disabled: !hayCambios, visible: !!editando, onClick: guardarEspacio },
    ]);

  }, [editando, espacio, espacioAEnviar, hayCambios]);

  return (
    <Paper sx={{ padding: '14px' }}>
      {!!procesando && <Dialog open={true}><DialogTitle>{procesando?.title ?? '' }</DialogTitle><DialogContent><CircularProgress/></DialogContent></Dialog>}
      {!!espacio
        ? <Box>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <Typography component="h2">Datos Generales</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="nombre"
                label="Nombre"
                name="nombre"
                value={nombre}
                disabled={!editando}
                autoComplete="family-name"
                onChange={(e) => {
                  setNombre(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2} >
              <FormControlLabel label="Activo" control={
                <Checkbox disabled={!editando} checked={activo} onChange={e => setActivo(e.target.checked)} />
              } />
            </Grid>
            <Grid item xs={12}>
              <Typography component="h2">Datos Comerciales</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="razonSocial"
                label="Razón Social"
                name="razonSocial"
                value={razonSocial}
                disabled={!editando}
                autoComplete="family-name"
                onChange={(e) => {
                  setRazonSocial(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                id="cuit"
                label="CUIT"
                name="cuit"
                value={cuit}
                disabled={!editando}
                autoComplete="family-name"
                onChange={(e) => {
                  setCuit(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="direccion"
                label="Dirección"
                name="direccion"
                value={direccion}
                disabled={!editando}
                autoComplete="family-name"
                onChange={(e) => {
                  setDireccion(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="ciudad"
                label="Ciudad"
                name="ciudad"
                value={ciudad}
                disabled={!editando}
                autoComplete="family-name"
                onChange={(e) => {
                  setCiudad(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component="h2">Responsable Comercial</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                  required
                  fullWidth
                  id="responsable"
                  label="Nombre"
                  name="responsable"
                  value={responsable}
                  disabled={!editando}
                  autoComplete="family-name"
                  onChange={(e) => {
                    setResponsable(e.target.value)
                  }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                  required
                  fullWidth
                  id="caracter"
                  label="Caracter"
                  name="caracter"
                  value={caracter}
                  disabled={!editando}
                  autoComplete="family-name"
                  onChange={(e) => {
                    setCaracter(e.target.value)
                  }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box>
                <Typography fontSize={12}>Firma registrada</Typography>
                <img src={firmaURLFull} width="50%" height="auto"></img>
              </Box>
            </Grid>
          </Grid>
        </Box>
        : <></>
      }
    </Paper>
  )
}

export default Espacio;