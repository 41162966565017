import { Box, Button, Paper, Typography } from '@mui/material';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { Component, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EditorTest = ({docURL}) => {

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const cambioDeEstado = (estado) => {
    setEditorState(estado);
  }

  const cargarHTML = () => {
    var xhr = new XMLHttpRequest();
    // const docURL = 'https://s3.amazonaws.com/media.lamaquinita.co/contratos/templates/1EmpresaschicaseIndividuos.docx.html';
    // const docURL = 'https://media.lamaquinita.co/contratos/templates/test.html';
    xhr.open('GET', process.env.REACT_APP_MEDIA_URL+'/'+docURL, true);
    xhr.onreadystatechange= function() {
        if (this.readyState!==4) return;
        if (this.status!==200) return; // or whatever error handling you want
      const html = this.responseText;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    };
    xhr.send();
  }

  const cargarEstado = () => {
    var xhr= new XMLHttpRequest();
    xhr.open('GET', 'https://s3.amazonaws.com/media.lamaquinita.co/contratos/templates/1EmpresaschicaseIndividuos.docx.html', true);
    xhr.onreadystatechange= function() {
        if (this.readyState!==4) return;
        if (this.status!==200) return; // or whatever error handling you want
      const html = this.responseText;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    };
    xhr.send();
  }

  const guardarEstado = () => {
    const content = editorState.getCurrentContent();
    const rawContent = convertToRaw(content);
    const markup = draftToHtml(rawContent);
    console.log(markup);
    console.log(JSON.stringify(rawContent));
    // Llamar a la API para guardar el content JSON stringified
  }

  return (
    <Box>
      <Button onClick={cargarHTML}>Abrir</Button>
      <Paper>
        <Editor editorState={editorState} onEditorStateChange={cambioDeEstado} />
      </Paper>
      <Button onClick={guardarEstado}>Guardar</Button>
    </Box>
  )
}

export default EditorTest;