import { Button, Checkbox, FormControlLabel, Grid, Input, InputLabel, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APIManager from '../../lib/apiManager';
import EditorTest from "../EditorTest";
import WYSIWYGEditor from "../WYSIWYGEditor";
import { Box } from '@mui/material';
import { CheckBox } from "@mui/icons-material";

const Template = ({setBotonera}) => {
  const [template, setTemplate] = useState(null);
  const [editando, setEditando] = useState(false);

  const [nombre, setNombre] = useState('');
  const [codigo, setCodigo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [contenido, setContenido] = useState(null);
  const [active, setActive] = useState(false);

  let { templateId } = useParams();

  const navigate = useNavigate();

  const nuevoTemplate = {
    id: null,
    codigo: '',
    nombre: '',
    active: false,
    body: 'Nuevo template',
    descripcion: '',
    fecha_alta: new Date(),
    revision: 1,
    template_file: '',
  }

  useEffect(() => {
    if (templateId === 'nuevo') {
      setTemplate(nuevoTemplate);
      setEditando(true);
    } else {
      APIManager.sendRequest('post', 'contratos', 'getTemplate', {id: templateId}, (response) => {
        console.log(response);
        if (response.success) {
          setTemplate(response.data.template);
        }
      })
    }
  }, [templateId]);

  const editarTemplate = () => {
    setEditando(!editando);
  }

  const templateActualizado = (templateBody) => {
    const templateActualizado = { ...template };
    // const buf = Buffer.from(templateBody);
    // templateActualizado.body = buf.toString('base64');
    templateActualizado.body = templateBody;
    templateActualizado.nombre = nombre;
    templateActualizado.codigo = codigo;
    templateActualizado.descripcion = descripcion;
    templateActualizado.active = active;

    return templateActualizado;

  }

  const guardarTemplate =  () => {
    const templateAEnviar = templateActualizado(contenido);

    APIManager.sendRequest('post', 'contratos', 'modificarTemplate', { template: templateAEnviar }, (response) => {
      if (response.success) {
        navigate('/templates');
      }
    });

  }

  const handleBodyChanged = (templateBody) => {
    setContenido(templateBody);
  }

  const cancelarEdicion = () => {
    setEditando(false);
    setNombre(template.nombre);
    setDescripcion(template.descripcion)
  }

  useEffect(() => {
    if (!!template) {
      setNombre(template.nombre);
      setCodigo(template.codigo);
      setDescripcion(template.descripcion)
      setActive(template.active);
      setContenido(template.body);
    }
  }, [template]);

  useEffect(() => {
    setBotonera([
      { title: 'Volver', label: 'Volver', variant: 'outlined', onClick: () => { navigate('/templates'); } },
      { title: 'Editar', label: 'Editar', variant: 'contained', visible: !editando, onClick: () => { setEditando(true) } },
      { title: 'Guardar', label: 'Guardar', variant: 'contained', disabled: !template, visible: !!editando, onClick: guardarTemplate },
    ]);
  }, [editando, nombre, codigo, descripcion, contenido, active]);

  return (
    <Paper sx={{ padding: '14px' }}>
    {!!template
        ? <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              id="nombre"
              label="Nombre"
              name="nombre"
              title={nombre}
              value={nombre}
              disabled={!editando}
              onChange={event => setNombre(event.target.value)}
            />
            <Typography width="100px">{'rev ' + template.revision }</Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              required
              fullWidth
              id="codigo"
              label="Código"
              name="codigo"
              title={codigo}
              value={codigo}
              disabled={!editando}
              onChange={event => setCodigo(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControlLabel control={<Checkbox
              defaultChecked
              fullWidth
              id="activo"
              name="active"
              title={''}
              checked={active}
              disabled={!editando}
              onChange={event => setActive(event.target.checked)}
            />} label="Activo" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="descripcion"
              label="Descripcion"
              name="descripcion"
              title={descripcion}
              value={descripcion}
              disabled={!editando}
              onChange={event => setDescripcion(event.target.value)}
            />
          </Grid>
          <Grid item sm={12}>
            {/* <Box padding="14px">{!editando && <Button title="Editar" onClick={editarTemplate} >Editar</Button>} */}
            {!!contenido && <WYSIWYGEditor editorReadOnly={!editando} templateBody={contenido} onSaveContent={handleBodyChanged} />}
            {/* </Box> */}
          </Grid>
        </Grid>
            // <Box display="flex" flexDirection="row" padding="14px">
              // <Input style={{width: "250px"}} title="Nombre" value={nombre} readOnly={!editando} onChange={(event) => setNombre(event.target.value)} />
              // <Typography width="100px">{'rev ' + template.revision }</Typography>
              // <Input style={{width: "100%"}} title="Descripción" value={descripcion} readOnly={!editando} onChange={(event) => setDescripcion(event.target.value)} />
            // </Box>
            // <Box padding="14px">{!editando && <Button title="Editar" onClick={editarTemplate} >Editar</Button>}
              // <WYSIWYGEditor editorReadOnly={!editando} docURL={template.template_file} templateBody={template.body} onSaveContent={guardarTemplate} onCancelar={cancelarEdicion} />
            // </Box>
          // </Box>
        : <></>
      }
    </Paper>
  )
}

export default Template;