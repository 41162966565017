import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SignaturePad from 'react-signature-canvas';
import APIManager from "../../lib/apiManager";
import { variablesAplicadas } from "../../lib/templateFunctions";

const FirmarContrato = ({ setBotonera }) => {

  let { contratoId } = useParams();
  const navigate = useNavigate();
  const sigRef = useRef();
  const [signature, setSignature] = useState(null);

  const [contrato, setContrato] = useState(null);
  const [template, setTemplate] = useState(null);
  const [nombreFirmante, setNombreFirmante] = useState('');
  const [cuitFirmante, setCuitFirmante] = useState('');
  const [razonSocialFirmante, setRazonSocialFirmante] = useState('');
  const [caracteres, setCaracteres] = useState([]);
  const [caracterFirmante, setCaracterFirmante] = useState('');
  const [firmaURL, setFirmaURL] = useState('');

  const [firmante, setFirmante] = useState(null);

  const [puedeGuardar, setPuedeGuardar] = useState('false');
  const [procesando, setProcesando] = useState(null);

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  }

  const trimSignature = () => {
    setFirmaURL(signature);
    sigRef.current.clear();
  }

  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  }

  const handleConfirmar = () => {
    setProcesando(true);

    APIManager.sendRequest('post', 'contratos', 'firmarContrato', { contrato, firmante }, response => {
      if (response.success) {
        firmante.firmaURL = response.data.firmaURL;
        const contratoParaDocumento = { ...contrato, firmante: firmante };
        console.log('generando PDF para ', contratoParaDocumento);
        variablesAplicadas(template, [], contratoParaDocumento, htmlBody => {
          APIManager.sendRequest('post', 'contratos', 'generarPDF', { documento: htmlBody, contrato: contratoParaDocumento }, response => {
            if (response.success) {
              // Se grabó el PDF y tenemos el filename para poner en el contrato
              window.open(response.data.documentoURL);
            }
            setProcesando(false);
            navigate('/contratos');
          });
        });
      }
    });
  }

  useEffect(() => {
    if (!!contratoId) {
      APIManager.sendRequest('post', 'contratos', 'getContrato', { id: contratoId }, (response) => {
        if (response.success) {
          setContrato(response.data.contrato);
          if (!!response.data.contrato.template_id) {
            cargarTemplate(response.data.contrato.template_id, (response) => {
              if (response.success) {
                setTemplate(response.data.template);
              }
            });
          }
        }
      })
    }
  }, [contratoId]);

  const cargarTemplate = (templateID, callback) => {
    APIManager.sendRequest('post', 'contratos', 'getTemplate', { id: templateID }, (templateResponse) => {
      if (templateResponse.success) {
        if (!!callback) {
          callback(templateResponse);
        }
      }
    });
  }

  useEffect(() => {
    if (!!contrato) {
      if (!!contrato.firmante && !!contrato.firmante_firma) {
        setNombreFirmante(contrato.firmante.nombre);
        setCuitFirmante(contrato.firmante.cuit);
        setRazonSocialFirmante(contrato.firmante.cuit);
        setFirmaURL(contrato.firmante_firma);
      }
    }
  }, [contrato]);

  useEffect(() => {
    setPuedeGuardar(!!nombreFirmante && !!cuitFirmante && !!razonSocialFirmante && !!caracterFirmante && !!firmaURL);
    setFirmante({
      nombre: nombreFirmante,
      razonSocial: razonSocialFirmante,
      cuit: cuitFirmante,
      caracter: caracterFirmante,
      firmaFile: firmaURL,
    });
  }, [nombreFirmante, cuitFirmante, razonSocialFirmante, caracterFirmante, firmaURL]);

  useEffect(() => {
    setBotonera([
      { title: 'Volver', label: 'Volver', variant: 'outlined', onClick: () => { navigate('/contratos'); } },
      { title: 'Confirmar', label: 'Confirmar', variant: 'contained', disabled: !puedeGuardar, onClick: handleConfirmar },
    ]);
  }, [puedeGuardar, firmante]);

  useEffect(() => {
    APIManager.getList('caracteres', {}, response => {
      if (response.success) {
        setCaracteres(response.data.listado);
      }
    })
  }, []);

  return (
    <Paper sx={{ padding: '14px' }}>
      {!!procesando && <Dialog open={true}><DialogTitle>{procesando?.title ?? '' }</DialogTitle><DialogContent><CircularProgress/></DialogContent></Dialog>}
      <Typography component="h2" fontSize="large">Datos del Firmante</Typography>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Stack justifyContent="flex-start">
            <SignaturePad canvasProps={{ className: 'signature'}} ref={sigRef} onEnd={handleSignatureEnd} />
            <Stack direction="row">
              <Button onClick={clearSignature}>Limpiar firma</Button>
              <Button onClick={trimSignature}>Capturar firma</Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <TextField
              required
              fullWidth
              id="nombre"
              label="Nombre"
              name="nombre"
              value={nombreFirmante}
              onChange={e=>setNombreFirmante(e.target.value)}
            />
            <TextField
              required
              fullWidth
              id="razonSocial"
              label="Razon Social"
              name="razonSocial"
              value={razonSocialFirmante}
              onChange={e=>setRazonSocialFirmante(e.target.value)}
            />
            <TextField
              required
              fullWidth
              id="cuit"
              label="CUIT"
              name="cuit"
              value={cuitFirmante}
              onChange={e=>setCuitFirmante(e.target.value)}
            />
{/*
            <TextField
              required
              fullWidth
              id="caracter"
              label="Caracter"
              name="caracter"
              value={caracterFirmante}
              onChange={e=>setCaracterFirmante(e.target.value)}
            />
*/}
            <FormControl variant="standard">
              <InputLabel id="caracter-label" shrink={true} >Caracter</InputLabel>
              <Select
                id="caracter-select"
                value={caracterFirmante}
                onChange={e=>setCaracterFirmante(e.target.value)}
                label="Caracter"
              >
                {caracteres.map(
                  (item => <MenuItem value={item.nombre}>{item.nombre}</MenuItem>)
                )}
              </Select>
            </FormControl>
            <Box>
              <Typography>Firma registrada</Typography>
              <img src={firmaURL} width="50%" height="auto"></img>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}


export default FirmarContrato;