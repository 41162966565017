import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, MenuItem, Select } from '@mui/material';
import { Modal, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APIManager from '../../../../lib/apiManager';
import { Input } from '@mui/material';
import { Box } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Stack } from '@mui/material';
import moment from 'moment';

const NuevoContratoModal = ({open, setOpen, cliente, templates, nuevoContratoPara, contratoAnterior}) => {

  const [clienteElegido, setClienteElegido] = useState(cliente);
  const [templateElegido, setTemplateElegido] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [vigenciaDesde, setVigenciaDesde] = useState((new Date).toISOString().substring(0, 10));
  const [contratoExistente, setContratoExistente] = useState(contratoAnterior);

  const navigate = useNavigate();

  const verificarContratoExistente = (cliente) => {
    APIManager.sendRequest('post','contratos','tieneContrato', {cliente}, (response) => {
      if (!!response.data && !!response.data.contrato) {
        setContratoExistente(response.data.contrato);
      } else {
        setContratoExistente(null);
      }
    });
  }

  const handleContinuar = () => {
    setOpen(false);
    nuevoContratoPara(clienteElegido, templateElegido, vigenciaDesde, contratoExistente);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleCancel = () => {
    handleClose();
    navigate(-1); // Back
  }

  const handleClienteElegido = (event) => {
    setClienteElegido(event.target.value);
    verificarContratoExistente(event.target.value);
  }

  const handleTemplateElegido = (event) => {
    setTemplateElegido(event.target.value);
  }

  useEffect(() => {

    // Si no hay cliente pre-seleccionado
    if (!cliente) {
      // Cargamos los clientes
      APIManager.getList('clientes', { orderBy: 'client_name', active: true }, (response) => {
        if (response.success) {
          setClientes(response.data.listado);
        }
      });
    }
  }, []);

  return (
    <Dialog
    open={open}
    aria-labelledby="nuevoContrato-dialog-title"
    aria-describedby="nuevoContrato-dialog-description"
  >
      <DialogTitle id="alert-dialog-title">
        Nuevo contrato
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="nuevoContrato-dialog-description">
          Seleccione {!cliente && "el cliente y "} el template de contrato a utilizar
        </DialogContentText>
        <Stack spacing={2} marginTop={2}>
          {!cliente &&
            <FormControl variant="standard" sx={{ width: "100%" }} error={!!contratoExistente}>
              {(!!clientes && (clientes.length > 0)) ?
                <>
                  <InputLabel id="cliente-label">Cliente</InputLabel>
                  <Select
                    labelId="cliente-label"
                    id="cliente-select"
                    value={clienteElegido}
                    onChange={handleClienteElegido}
                    label="Cliente"


                  >
                    {!!clientes && clientes.map(
                      (clienteItem => <MenuItem value={clienteItem}>{clienteItem.nombre}</MenuItem>)
                    )}
                  </Select>
                  {!!contratoExistente && <FormHelperText variant='primary'>El cliente ya tiene un contrato (se renovara el existente)</FormHelperText>}
                </>
                : <Stack direction="row" alignItems="center">
                  <Typography>Cargando clientes...</Typography>
                    <CircularProgress labelId="cliente-label" />
                </Stack>
              }
            </FormControl>
          }
          <FormControl variant="standard" sx={{ width: "100%" }}>
            {(!!templates && (templates.length > 0)) ?
              <>
                <InputLabel id="template-label">Template</InputLabel>
                <Select
                  labelId="template-label"
                  id="template-select"
                  value={templateElegido}
                  onChange={handleTemplateElegido}
                  label="Template"
                >
                  {!!templates && templates.map(
                    (templateItem => <MenuItem value={templateItem}>{templateItem.nombre}</MenuItem>)
                  )}
                </Select>
              </>
              : <Stack direction="row" alignItems="center">
                  <Typography>Cargando templates...</Typography>
                    <CircularProgress labelId="template-label" />
                </Stack>
              }
          </FormControl>
          <FormControl focused variant="standard" sx={{ width: "100%" }}>
            <InputLabel id='vigenciaDesde-label'>Vigencia Desde</InputLabel>
            <Input labelId="vigenciaDesde-label" type='date' id="vigenciaDesde-input" title='Vigencia Desde' value={vigenciaDesde} onChange={(event) => setVigenciaDesde(event.target.value)}></Input>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button onClick={handleContinuar} disabled={!clienteElegido || !templateElegido}>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NuevoContratoModal;