import { useEffect, useState } from 'react';
import { Card, FormControl, Grid, Input, InputLabel, Typography } from '@mui/material';

const UsuarioCard = ({ usuario, enabled, raised, onChange }) => {
  const [nombres, setNombres] = useState(usuario.nombres);
  const [apellidos, setApellidos] = useState(usuario.apellidos);
  const [email, setEmail] = useState(usuario.email);
  const [emailDeContacto, setEmailDeContacto] = useState(!!usuario.emailDeContacto ? usuario.emailDeContacto : usuario.contact_email);

  const itemActualizado = () => {
    const nuevoItem = Object.assign({}, usuario);
    nuevoItem.nombres = nombres;
    nuevoItem.apellidos = apellidos;
    nuevoItem.email = email;
    nuevoItem.emailDeContacto = emailDeContacto;

    return nuevoItem;

  }

  useEffect(() => {
    onChange(itemActualizado());
  }, [nombres, apellidos, email, emailDeContacto])

  return <Card raised={raised} sx={{ padding: '14px' }}>
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Typography fontSize="large">Datos del Usuario</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl variant="standard">
          <InputLabel id="email-label" >Email / Nombre de usuario</InputLabel>
          <Input
            disabled={!enabled}
            labelId="email-label"
            id="email-input"
            value={email}
            onChange={(event) => { setEmail(event.target.value) }}
            required
            >
          </Input>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl variant="standard">
          <InputLabel id="nombres-label" >Nombres</InputLabel>
          <Input
            disabled={!enabled}
            labelId="nombres-label"
            id="nombres-input"
            value={nombres}
            onChange={(event) => { setNombres(event.target.value) }}
            required
          >
          </Input>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl variant="standard">
          <InputLabel id="apellidos-label" >Apellidos</InputLabel>
          <Input
            disabled={!enabled}
            labelId="apellidos-label"
            id="apellidos-input"
            value={apellidos}
            onChange={(event) => { setApellidos(event.target.value) }}
            required
          >
          </Input>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl variant="standard">
          <InputLabel id="emailDeContacto-label" >Email de Contacto</InputLabel>
          <Input
            disabled={!enabled}
            labelId="emailDeContacto-label"
            id="emailDeContacto-input"
            value={emailDeContacto}
            onChange={(event) => { setEmailDeContacto(event.target.value) }}
            required
          >
          </Input>
        </FormControl>
      </Grid>
    </Grid>
  </Card>
}

export default UsuarioCard;