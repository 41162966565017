import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select } from '@mui/material';
import { Modal, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '@mui/material';
import { Box } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Stack } from '@mui/material';
import APIManager from '../../../../lib/apiManager';

const NuevoExcluidoModal = ({open, setOpen, setSelectedItem}) => {

  const [clienteElegido, setClienteElegido] = useState(null);
  const [clientes, setClientes] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // Cargamos los clientes
    APIManager.getList('clientes', { orderBy: 'client_name', active: true }, (response) => {
      if (response.success) {
        setClientes(response.data.listado);
      }
    });
  }, []);

  const handleContinuar = () => {
    setOpen(false);
    setSelectedItem(clienteElegido);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleCancel = () => {
    handleClose();
  }

  const handleClienteElegido = (event) => {
    setClienteElegido(event.target.value);
  }

  return (
    <Dialog
    open={open}
    aria-labelledby="nuevoContrato-dialog-title"
    aria-describedby="nuevoContrato-dialog-description"
  >
      <DialogTitle id="alert-dialog-title">
        Excluir cliente
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="nuevoContrato-dialog-description">
           Seleccione el cliente a excluir
        </DialogContentText>
        <Stack spacing={2} marginTop={2}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            {(!!clientes && (clientes.length > 0)) ?
              <>
                <InputLabel id="cliente-label">Cliente</InputLabel>
                <Select
                  labelId="cliente-label"
                  id="cliente-select"
                  value={clienteElegido}
                  onChange={handleClienteElegido}
                  label="Cliente"
                >
                  {!!clientes && clientes.map(
                    (clienteItem => <MenuItem value={clienteItem}>{clienteItem.nombre}</MenuItem>)
                  )}
                </Select>
              </>
              : <Stack direction="row" alignItems="center">
                <Typography>Cargando clientes...</Typography>
                  <CircularProgress labelId="cliente-label" />
              </Stack>
            }
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button onClick={handleContinuar} disabled={!clienteElegido}>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NuevoExcluidoModal;